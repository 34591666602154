*{
  padding: 0;
  margin: 0;
}

#Tablas_blanco{
  background: white;

}


#Modal_Logs{
  max-width: 95vw;
  min-width: flex;
  min-height: 100vw;
  margin-top: 0%;
  margin-left: 3vw;
  margin-right: 3vw;
  margin-bottom: 0%;
  padding-left: 0%;
  padding-right: 0vw;
}


#Tabla_logs{
  min-width: 70vw;
  font-size: 90%;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*Contraseña- LOGIN*/
#RecuperarAcc{
  margin-left: 20%;
}

/*Fondo */

#Trabajo{
  height: 100vh;
  background-color: #0C6907;
  position: relative;
  margin-left: 0%;
  padding-left: 0%;
  margin-right: 0%;
  padding-right: 0%;
  min-width: 100%;
  min-height: 100%;
  
}

#Trabajo2{
  height: 30vh;
  background-color: #0C6907;
  position: relative;
  margin-left: 0%;
  padding-left: 0%;
  margin-right: 0%;
  padding-right: 0%;
  min-width: 100%;
  min-height: 100%; 
}

/*Trigo*/
#Fondo_trigo{
  background:url('../public/Imagenes/trigo.png');
  background-repeat: no-repeat;
}

/*Elementos de /Inventarios /Produccion*/

#Boton-inventario{
  margin-left: 10%;
  margin-right: 10%;
  font-size: 150%;
}


/*Elementos de Inicio-login */
#Inicio-login-fondo{
  background-color: #0C6907;
  padding-top: 5%;
  padding-bottom: 10%;
  width: 100%;
}

#Inicio-login-fondo-2{
  background-color: #798678;
  color: black;
  padding-left: 0%;
  padding-top: 2%;
  padding-bottom: 2%;
  margin-left: 0%;
  width: 60%;
}

#Inicio-login-label{
  font-size: 200%;
}

#boton-login{
  margin-top: 1%;
  background-color: whitesmoke;
  width: 20%;
  font-size: 200%;
  color: black;
}

/*Dropdown de productos de navegacion de inicio*/
#Dropdown-nav-inicio{
  background-color: white;
  color: black;
  border: 0px;
  padding-top: 8%;
}

/*NAVBAR de inicio*/
#Navbar{
  width: 100%;
  position: fixed;
  z-index: 100;
}

/*Imagen del nav bar luipack*/
#img_luipack{
  margin-right: 1vw;
}

#Botonlogin{
  background-color: white;
  color: black;
  font-size: 150%;
}

/*Navbar trabajo*/
#Navbar_trabajo{
  min-width: 100%;
  border: 2px solid black;
  margin-left: 0%;
  padding-left: 0%;
  width: 100%;
  position: fixed;
  z-index: 100;
}

#Navbar_trabajo_home{
  padding-top: 5%;
}

#Contactos{
  color: #F0F2EF;
  margin-left: 2%;
  margin-top: 3%;
  padding-top: 0%;
  margin-right: 0%;
  width: 40%;
  float: left;
  font-size: 100%;
  position: absolute;
}

#Direccion{
  margin-top: 3%;
  color: #F0F2EF;
  margin-left: 0%;
  margin-right: 20%;
  float: right;
  font-size: 100%;

  
}

/*TABLAS DE AGREGAR PRODUCTO*/

#Agregar_inventario{
  background-color: white;
}

#editusuario{
  background-color: white;
}

#agregarproducto{
  width: 30%;
  margin-top: 0%;
  margin-bottom: 0%;
  padding-top: 2%;
}

/*LISTA DESPLEGABLE EN INVENTARIOS, ETC*/
#listas{
  font-size: 150%;
  margin-top: 0%;
  padding-top: 0%;
}

#tabla{
  margin-top: 5%;
  background-color: white;
}

/*Listas de secciones (titulos)*/
#Titulo_secciones{
  color: white;

}

/*Lista empaque*/
#Lista_empaque{
  
  background-color: #0C6907;
  width: 95%;
  max-width: 98%;
  margin: 1% auto;
  display: grid;
  grid-gap: 1%;
  border-radius: 4px;
  padding: 1%;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(5, 14vh);
}

#boton_lista_campo_grid{
  width:  65%;
  height: 70%;
  margin: auto;
}

@media screen and (max-width: 760px){
	#boton_lista_campo_grid{
    font-size: 80%;
	}
}

/*CheckBox embarque producción*/
#Embarque{
  
  background-color: #0C6907;
  width: 95%;
  max-width: 98%;
  margin: 1% auto;
  display: grid;
  grid-gap: 1%;
  border-radius: 4px;
  padding: 1%;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(5, 5vh);
}

#checkbox_embarque_2{
  width:  auto;
  height: auto;
  margin: auto;
}

@media screen and (max-width: 760px){
	#checkbox_embarque_2{
    font-size: 80%;
	}
}


/*Lista empaque copia*/
/* #Lista_empaque{
  font-size: 150%;
  padding-left: 40%;
  padding-right: 40%;
 background-color: #0C6907;
border: 3px solid white;
	display: grid;
	grid-gap: 20%;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: repeat(2, auto);

	grid-template-areas: "header header header"
						 "contenido contenido sidebar"
						 "widget-1 widget-2 sidebar"
						 "footer footer footer";
} */

/*MODAL MOVIMIENTO*/
#Modal_movimiento{
margin: auto;
margin-right: 0%;
padding-left: 0%;
padding-right: 0%;
}

/*TITULO DE LA PAGINA INVENTARIOS*/
#Titulo_inventarios{
  margin-top: 0%;
  background-color: whitesmoke;
}

/*BOTON regresar a ordenes*/
#boton_regresar_ordenes{
  margin-left: 5%;
}

/*Botones de fin de ciclo o inicio ciclo*/
#inicio_ciclo{
  width: 30%;
  height: 30%;
  margin-top: 10%;
  font-size: 200%;
}

#fin_ciclo{
  width: 30%;
  height: 30%;
  margin-top: 10%;
  margin-left: 5%;
  font-size: 200%;
}

#Login_contraseña{
  background-color: white;
  padding: 5%;
  border: 3px solid black;
  padding-top: 2%;
  padding-bottom: 1%;
}


/* TODO SOBRE LAS LISTAS BOTONES DE LAS LISTAS */
#Titulo_presentaciones{
  padding-top: 1%;
  background-color: #0C6907;
  color: white;
}

#Titulo_presentaciones2{
  padding-top: 2%;
  background-color: #0C6907;
  color: white;
}

#Fondo_verde{
  background-color: #0C6907; 
}

#Botones_presentaciones{
  background-color: grey;
  margin-top: 5%;
  margin-right: 5%;
  font-size: 150%;
}

#Botones_regresar{
  margin-top: 7%;
  margin-left: 70%;
  
}

#Boton_regresar_menu_principal_presentaciones{
margin-left: 2%;
}

#Boton_regresar_menu_principal_presentaciones2{
  margin-left: 0%;
  margin-right: 2%
  }

  #Botones_agregareliminar_categoria_almacen_campo{
    margin-left: 0%;
    margin-right: 0%;
    margin-top: 0%;
    max-width: 90%;
    min-width: 90%;
    padding-top: 7%;
    margin-bottom: 0%;
    padding-bottom: 0%;
    font-size: 110%;
    padding-bottom: 0.3%;
    background-color: #0C6907; 
    }  

#Botones_01{
  margin-right: 10%;
  font-size: 110%;
}

#Botones_02{
  font-size: 110%;
}

@media screen and (max-width: 760px){
	#Botones_01,
  #Botones_02{
    font-size: 50%;
    margin-top: 10%;
	}
}

/* LISTA DE CULTIVO EN PROCESO */

#Botones_regresar{
  margin-top: 7%;
  margin-left: 70%;
  
}

#Boton_regresar_menu_principal_presentaciones{
margin-left: 2%;
}

#Boton_regresar_menu_principal_presentaciones2{
  margin-left: 0%;
  margin-right: 2%
  }

  #Botones_agregareliminar_categoria_cultivo_en_proceso{
    margin-left: 0%;
    margin-right: 0%;
    margin-top: 0%;
    max-width: 90%;
    min-width: 90%;
    padding-top: 7%;
    margin-bottom: 0%;
    padding-bottom: 0%;
    font-size: 110%;
    padding-bottom: 0.3%;
    background-color: #0C6907; 
    }  

#Botones_01{
  margin-right: 10%;
  font-size: 110%;
}

#Botones_02{
  font-size: 110%;
}

#Botones_03{
  margin-right: 10%;
  margin-left: 10%;
  font-size: 110%;
}

#Botones_04{
  font-size: 110%;
}

@media screen and (max-width: 760px){
	#Botones_01,
  #Botones_02,
  #Botones_03,
  #Botones_04{
    font-size: 50%;
    margin-top: 10%;
	}
}

#Lista_cultivo_en_proceso{
  
  background-color: #0C6907;
  width: 95%;
  max-width: 98%;
  margin: 1% auto;
  display: grid;
  grid-gap: 1%;
  border-radius: 4px;
  padding: 1%;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(5, 10vh);
}

#boton_lista_cultivo_en_proceso{
  width:  auto;
  height: auto;
  margin: auto;
  padding: 5px;
}

@media screen and (max-width: 760px){
	#boton_lista_cultivo_en_proceso{
    font-size: 80%;
	}
}



  /* Grafica */
  #Grafica{
    height: 5%;
    max-height: 5%;
    max-width: 65%;
  }

/* TODO LO REFERENTE A LA PAGINA DE INICIO */
/* Principal */

.Contenedor_inicio{
  height: auto;
  background-color: #0C6907;
  width: 100%;
  max-width: 100%;
  margin: 0%;
  display: grid;
  grid-gap: 0%;
  border-radius: 4px;
  padding: 0%;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(5, auto);

  grid-template-areas:  "Navbar Navbar"
                        "Carrusel Carrusel"
                        "Quienes_somos Quienes_somos"
                        "Mision Vision"
                        "Footer Footer";
}

.Navbar{
  grid-area: Navbar;
}

.Carrusel{
  grid-area: Carrusel;
}

.Quienes_somos{
  background-color: #0C6907;
  height: auto;
  color: #F0F2EF;
  padding-top: 2%;
  font-size: 100%;
  padding-bottom: 0%;
  margin-bottom: 0%;
  grid-area: Quienes_somos;

}

.Mision{
  margin-top: 5%;
  background-color: #0C6907;
  color: #F0F2EF;  
  height: auto;
  font-size: 100%;
  grid-area: Mision;

}

.Vision{
  margin-top: 5%;
  text-align: center;
  background-color: #0C6907;
  color: #F0F2EF;  
  height: auto;
  font-size: 100%;
  grid-area: Vision;
}

.Texto_justificado{
  text-align: justify;
  padding: 2%;
}


.Footer{
  margin-bottom: 0%;
  padding-bottom: 0%;
  text-align: bottom;
  background-color: #0C6907;
  height: 1%;
  color: #F0F2EF;
  padding-top: 5%;
  padding-bottom: 0%;
  grid-area: Footer;
}
  

  @media screen and (max-width: 700px){
    .Contenedor_inicio{
      height: auto;
      background-color: #0C6907;
      width: 100%;
      max-width: 100%;
      margin: 0%;
      display: grid;
      grid-gap: 0%;
      border-radius: 4px;
      padding: 0%;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(6, auto);
    
      grid-template-areas:  "Navbar Navbar"
                            "Carrusel Carrusel"
                            "Quienes_somos Quienes_somos"
                            "Mision Mision"
                            "Vision Vision"
                            "Footer Footer";
    }

    .Carrusel{
      padding-top: 5%;
      grid-area: Carrusel;
    }
  }


/* TODO SOBRE LAS PAGINAS DE PRODUCTOS (TOMATE,CALAZABA,ETC) */
/*Se usa en */
#Padre_tomate{
  position: relative;
  display: flex;
  padding-top: 0vw;
  margin-top: 0%;
}


.Hijo_tomate{
  position: absolute;
  background-color: transparent;
  right: 20vw;
  top: 20vw;
  border: 2px solid white;
  border-radius: 25px;
  width: 60%;
  height: 50%;
  margin-top: -10vw;
  opacity: 0.9; /*lo hace menos brilloso */
  backdrop-filter: blur(15px);
  font-size: 2vw;
}

.Hijo_jalapeño{
  position: absolute;
  background-color: transparent;
  right: 20vw;
  top: 20vw;
  border: 2px solid white;
  border-radius: 25px;
  width: 60%;
  height: 25%;
  margin-top: -10vw;
  opacity: 0.9; /*lo hace menos brilloso */
  backdrop-filter: blur(10px);
  font-size: 2vw;
}

#Hijo_tomate_texto{
  padding-top: 2%;
  color: white;
  font-size: 1vw;
}
#Descripcion{
  font-size: 1.5vw;
}

#Presentaciones{
  padding-left: 0%;
  width: 30%;
  margin-left: 0%;
  position: absolute;
  left: 0;
  font-size: 1.8vw;
}
#Presentaciones_tamaños{
  
  padding-left: 0%;
  width: 5vw;
  margin-left: 10%;
  position: absolute;
  left: 0;
  font-size: 1.6vw;
}

/*Se usa en */
/*bacdrop-filter es para degradado del fondo*/
.Hijo_no_blanco{
  position: absolute;
  background-color: transparent;
  right: 20vw;
  top: 20vw;
  border: 2px solid black;
  border-radius: 25px;
  width: 60%;
  height: 50%;
  margin-top: -10vw;
  opacity: 0.9; /*lo hace menos brilloso */
  backdrop-filter: blur(15px);
  font-size: 2vw;
}

#Hijo_no_blanco_texto{
  padding-top: 2%;
  color: black;
  font-size: 1vw;
}


#Canvas_contactos{
  background-color: black;
  color: white;
  font-size: 150%;
  
}

/*Loading*/
#Loading_pages{
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}


/* Ejemplo filtrado tablas boostrap */
